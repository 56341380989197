import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";

const CanalDePrivacidade = () => {
  const img = useStaticQuery(
    graphql`
      query {
        img: file(relativePath: { eq: "canal_privacidade.png" }) {
          childImageSharp {
            fluid {
              src
            }
          }
        }
      }
    `
  );

  const imageSrc = img?.img.childImageSharp.fluid.src;

  return (
    <Layout footerColor>
      <div className="pb-15">
        <img className="mx-auto" src={imageSrc} alt="Canal de privacidade" />
        <div className="max-w-5xl mx-auto space-y-5">
          <h1 className="text-4xl">Canal de Privacidade</h1>
          <p>
            As Instituições de Ensino de toda a rede da Ânima Educação valorizam
            a relação de confiança com todas as partes com as quais se
            relacionam e estão comprometidas com a proteção e a privacidade dos
            dados pessoais. Nesse sentido, cumprem rigorosamente e com muita
            seriedade a legislação aplicável sobre o tema, com destaque para a
            Lei Geral de Proteção de Dados n° 13.709/2018 (LGPD).
          </p>
          <p>
            A Data Protection Officer do Grupo Ânima é Paula Starling. Se você
            possui qualquer dúvida ou reclamações sobre como tratamos o seu dado
            pessoal, por favor, contate nossa Data Protection Officer no
            endereço{" "}
            <Link
              to="mailto:privacidade@animaeducacao.com.br"
              className="text-accent"
            >
              privacidade@animaeducacao.com.br.{" "}
            </Link>
          </p>
          <p>
            Se você deseja exercer seus direitos de acessar, retificar, deletar
            ou se recusar ao tratamento de seus dados pessoais, preencha nosso
            formulário de requisição{" "}
            <Link
              to="https://privacy-central.securiti.ai/#/dsr/866478e9-5e2d-4e34-8b4d-f9117a1230d5"
              className="text-accent"
            >
              clicando aqui.{" "}
            </Link>
          </p>
          <p>
            Confira aqui nosso{" "}
            <Link
              to="https://www.unisul.br/wp-content/uploads/2023/03/Aviso-Externo-de-Privacidade-GRADUACAO-E-POS.pdf"
              className="text-accent"
            >
              Aviso Externo de Privacidade.
            </Link>
          </p>
          <p>
            <Link
              to="https://ri.animaeducacao.com.br/show.aspx?idCanal=xhHMDaOFpIcbtcYg4xW9Bg==&_ga=2.110613570.677019355.1686154347-931714186.1686154347&_gl=1*1wq1whk*_ga*OTMxNzE0MTg2LjE2ODYxNTQzNDc.*_ga_QTN4ESST95*MTY4NjE2MDQ5OC4yLjEuMTY4NjE2MjQ3OC4wLjAuMA.."
              className="text-accent"
            >
              Política Corporativa de Privacidade Ânima.
            </Link>
          </p>
          <h1 className="text-3xl">Descadastramento de comunicações</h1>
          <p>
            Se você deseja inserir o pedido de descadastramento de seus dados
            pessoais para fins de envio de comunicação de marketing, propaganda
            e publicidade, bloqueando nossas comunicações, preencha nosso
            formulário de requisição:{" "}
            <Link to="https://optout.animaeducacao.com.br/unisul?_ga=2.81779920.677019355.1686154347-931714186.1686154347&_gl=1*1xs8jq*_ga*OTMxNzE0MTg2LjE2ODYxNTQzNDc.*_ga_QTN4ESST95*MTY4NjE2MDQ5OC4yLjEuMTY4NjE2MjQ3OC4wLjAuMA..">
              Clique aqui.{" "}
            </Link>
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default CanalDePrivacidade;
